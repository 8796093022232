import React from "react";
import { withRouter } from "react-router-dom";
import "./AboutPage.css";
import { Row, Col } from "react-bootstrap";
import MetaTags from "react-meta-tags";

function AboutPage(props) {
  return (
    <div className="container aboutPage col-lg-6 offset-lg-3 ">
      <MetaTags>
        <title>About Shakespeare</title>
        <meta name="description" content="About Shakespeare page" />
      </MetaTags>
      <br />
      <Row className="justify-content-center">
        <Col className="text-center col-4">
          <figure>
            <img
              className="app-heads greyed-out"
              src={`../assets/divinator.png`}
              alt="Divinator logo"
              onClick={() => props.history.push("/aboutDivinator")}
            />
            <figcaption>About Divinator</figcaption>
          </figure>
        </Col>
        <Col className="text-center col-4">
          <figure>
            <img
              className="app-heads"
              alt="Shakespeare Logo"
              src={`../assets/shakespeare.png`}
            />
            <figcaption>About Shakespeare</figcaption>
          </figure>
        </Col>
      </Row>
      <Row className="justify-content-center spacing">
        <Row className="flex-column paragraph">
          Shakespeare Divinator listens intelligently as it engages you in
          personal and spiritual conversations using the actual lines of
          Shakespeare. Exercise your infinite curiosity as it communicates in a
          way that ranges from inspiring to witty.
        </Row>
        <Row className="flex-column paragraph">
          Its linguistically advanced language parser and carefully-chosen
          replies make every conversation a one-of-a-kind high-tech renaissance
          experience. Think Turing test meets psychic philosopher.
        </Row>
      </Row>
      <div className="spacing">
        <Row>
          <Col xs sm md lg xl="12">
            <Row className="title">WAYS TO USE:</Row>
            <Row className="flex-column paragraph">
              <ol>
                <li>Ask about the future.</li>
                <li>
                  Converse to illuminate your inner mind or daily thoughts.
                </li>
                <li>
                  Explore philosophical questions (space, time, free will,
                  morality, truth).
                </li>
                <li>Talk to your creator or invite spiritual enlightenment.</li>
                <li>Ask Shakespeare Divinator about its self.</li>
                <li>
                  Address the mystery of life on Earth or the nature of the
                  universe.
                </li>
                <li>
                  Give/receive psychic readings, or ask what others are
                  thinking/feeling (a tarot cards, astrology, divination,
                  clairvoyance alternative).
                </li>
                <li>Gain psychological insights.</li>
                <li>Challenge Shakespeare Divinator to a wackiness joust.</li>
              </ol>
            </Row>
          </Col>
        </Row>

        <Row className=" flex-column paragraph">
          As a master of human foibles and the human condition, Shakespeare
          makes a most ispacingnteresting dialogue partner. Freely ask him
          anything you wish, from serious to playful, but ask from the heart.
          Find out what he has in mind any time you wish.
        </Row>
        <Row className=" flex-column paragraph">
          The great Bard may respond as an oracle, a witty challenging friend,
          or even a teacher/adviser. Possessing its own sort of volition, it may
          reply in a surprising way that often should be recognized as an
          invitation to follow a new idea. If you're ready to match wits, try a
          philosophical conversation, or just looking to pass some time
          constructively, this experience is for you.
        </Row>
        <Row className=" flex-column paragraph">
          Whether the Shakespeare Divinator experience will be merely
          entertaining or absolutely stellar in any dialogue may actually be
          determined by your willingness to interact, your creativity, and the
          quality of your consciousness.
        </Row>
      </div>

      <Row className="spacing">
        <Row className="title">A HISTORICAL FIGURE</Row>
        <Row className="flex-column paragraph">
          William Shakespeare is recognized for his plays and poetry as the
          premier writer of the English language. His works explore universal
          character traits, ideas, and emotions with a masterful style that has
          endured the test of centuries.
        </Row>
        <Row className="flex-column paragraph">
          Yet little is widely known of the man ...that he was born in
          Stratford-Upon-Avon in April, 1564, or that he attended a grammar
          school that gave him a fine classical/traditional education that
          broadened the knowledge contained in his works comprised of 36 plays,
          including comedies, tragedies, histories, and romances, as well as 154
          sonnets and two poetic romances.
        </Row>
        <Row className="flex-column paragraph">
          Personal facts, however, cannot begin to convey the influence of
          Shakespeare, the quintessential voice of the Renaissance in the
          English language. Indeed, a philosopher speaks through his characters
          of life, death, of good and evil, love, devotion, greed, envy, and
          ambition.
        </Row>
        <Row className="flex-column paragraph">
          As a master observer of the psyche, his characters reveal many subtle
          motivations behind human actions, and he has presented these
          inspirations, vices, and foibles with insightful depth. Perhaps it was
          this that led Oscar Wilde to crown Shakespeare "the most purely human
          of all the great artists".
        </Row>
      </Row>
      <Row className="spacing">
        <Row className="title">POTENTIAL EXPANSION</Row>
        <Row className="flex-column paragraph">
          The existence of Shakespeare Divinator demonstrates that any author's
          works can be brought to life via The Divinator’s language parser and
          framework. While Shakespeare makes a most interesting dialogue
          partner, it’s exciting to imagine posing questions to the works of ANY
          author you admire. The possibilities are astronomical.
        </Row>
      </Row>
      <Row className="spacing">
        <Row className="title">DISCLAIMER</Row>
        <Row className="flex-column paragraph ">
          Shakespeare Divinator is not to be construed as a substitute for
          professional advice on any matter. By using it, you assume
          responsibility for your interpretation of your conversations, any
          actions taken, and any consequences thereof, and relieve the website
          creators and the web hosting company of any responsibility or
          liability.
        </Row>
      </Row>
    </div>
  );
}

export default withRouter(AboutPage);
