import React, { Component } from "react";
import "./Conversation.css";
import ConversationContent from "./ConversationContent";
import { withFirebase } from "../Firebase";

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.loadMoreRows = this.loadMoreRows.bind(this);
    this.state = { conversations: [] };
  }
  render() {
    return (
      <ConversationContent
        {...this.props}
        loadMoreRows={this.loadMoreRows}
        conversations={this.state.conversations}
        scrollIndex={this.state.scrollIndex}
        rows={this.state.rows}
      />
    );
  }

  loadMoreRows() {
    return;
    if (!this.state.conversations[0]) {
      return;
    }
    this.listener = this.props.firebase
      .getConversationRef(this.props.uid, this.props.themeName)
      .orderByChild("timestamp")
      .endAt(this.state.conversations[0]["timestamp"])
      .limitToFirst(20)
      .once("value", snapshot => {
        if (snapshot.val() !== null) {
          let keys = Object.keys(snapshot.val());

          this.setState(prevState => {
            return {
              conversations: [
                //pop the last one off since it was already added in the last get
                ...Object.values(snapshot.val() || []).slice(0, -1),

                ...prevState.conversations
              ],
              scrollIndex: keys.length - 1
            };
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.themeName !== this.props.themeName) {
      this.setState({ conversations: [] });

      this.props.firebase
        .getConversationRef(this.props.uid, this.props.themeName)
        .off("child_added");

      this.listener = this.props.firebase
        .getConversationRef(this.props.uid, this.props.themeName)
        .limitToLast(1000)
        .on("child_added", snapshot => {
          if (snapshot.val() !== null) {
            let newConv = snapshot.val();
            //
            this.setState(prevState => {
              let prevConversations = prevState.conversations;
              let updatedConv = [...prevConversations, newConv];
              return {
                conversations: updatedConv,
                scrollIndex: updatedConv.length
              };
            });
          }
        });
    }
  }

  componentDidMount(props) {
    this.listener = this.props.firebase
      .getConversationRef(this.props.uid, this.props.themeName)
      .limitToLast(1000)
      .on("child_added", snapshot => {
        let newConv = snapshot.val();
        if (newConv !== null) {
          this.setState(prevState => {
            let newChat = [...prevState.conversations, newConv];

            return {
              conversations: newChat,
              scrollIndex: newChat.length
            };
          });
        }
      });
  }
}

export default withFirebase(Conversation);
