import React from "react";
import LoginForm from "./LoginFormBase";
import "./LoginPage.css";
import MetaTags from "react-meta-tags";

const LoginBase = () => {
  return (
    <div className="login-page">
      <MetaTags>
        <title>Sign in</title>
        <meta name="description" content="Sign in page" />
      </MetaTags>
      <LoginForm />
    </div>
  );
};

export default LoginBase;
