import React from "react";
import ChatBox from "../ChatBox/ChatBox";
import Heads from "../Heads/Heads";
import "./ChatPage.css";
import { AuthUserContext } from "../Session";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

const ANON_WARNING =
  "You are signed in as anonymous. You may converse freely with The Divinator. No conversations are stored";

const themeToTitle = {
  divinator: "The Divinator",
  shakespeare: "Shakespeare Divinator",
};

function ChatPage(props) {
  let reactSwipeEl;
  let themeName = props.location.pathname.slice(1);
  return (
    <div>
      <MetaTags>
        <title>{`${themeToTitle[themeName]} chat`} </title>
        <meta name="description" content="Chat page" />
      </MetaTags>
      <AuthUserContext.Consumer>
        {(authUser) => {
          return authUser ? (
            <div
              style={{ width: "100vw", height: "100vh" }}
              className={"background-divinator"}
            >
              <Heads
                onClick={reactSwipeEl ? reactSwipeEl.next() : () => {}}
                themeName={themeName}
              />

              <div className="parent ">
                <div className="center-conversation">
                  <ChatBox
                    themeName={themeName}
                    entityDisplayName={themeToTitle[themeName]}
                    displayName={authUser.displayName}
                    uid={authUser.uid}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>Please log in!</div>
          );
        }}
      </AuthUserContext.Consumer>
    </div>
  );
}

export default withRouter(ChatPage);
