import React from "react";
import "./NavBar.css";
import { Navbar, Nav } from "react-bootstrap";
import SignOut from "../SignOut/SignOut";
function NavigationBar() {
  return (
    <Navbar expand="lg" className="navBar">
      <Navbar.Brand href="/">
        <img
          src={require("../../images/logo.png")}
          alt="logo"
          className="nav-image"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-center d-flex flex-fill">
          <Nav.Link className="nav-link" href="/welcome">
            Home
          </Nav.Link>
          <Nav.Link className="nav-link" href="/divinator">
            Chat
          </Nav.Link>
          <Nav.Link className="nav-link" href="/aboutDivinator">
            About
          </Nav.Link>
          <Nav.Link className="nav-link" href="/contact">
            Contact
          </Nav.Link>
          <Nav.Link className="nav-link">
            <SignOut />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
