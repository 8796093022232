import React from "react";
import SignUpForm from "./SignUpFormBase";
import "./SignUpPage.css";
import MetaTags from "react-meta-tags";

const SignUpBase = () => {
  return (
    <div className="sign-up-page">
      <MetaTags>
        <title>Sign up</title>
        <meta name="description" content="Sign up page" />
      </MetaTags>
      <SignUpForm />
    </div>
  );
};

export default SignUpBase;
