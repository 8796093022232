import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import "./ResponseBar.css";
import getIntent from "../intents";
import ignoreSet from "../../constants/ignoreSet";
import { themeMap } from "../../theme";
import { sendQueryToFirebase } from "../Firebase/functions";

class QueryBar extends Component {
  constructor(props) {
    super(props);
    this.state = { query: "" };
    this.lastQuery = "";
    this.numRepetitions = 0;
  }

  cleanupQuery = (query) =>
    query
      .split(" ")
      .filter((word) => !ignoreSet.has(word))
      .filter((word) => word.length > 3)
      .join(" ");

  checkForRepetition = (query) => {
    if (query === this.lastQuery) {
      this.numRepetitions += 1;
    } else this.numRepetitions = 0;

    this.lastQuery = query;
    if (this.numRepetitions === 3) {
      this.numRepetitions = 0;
      return true;
    }
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { query } = this.state;

    let cleanQuery = this.cleanupQuery(query);

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    document.getElementById("query-bar").focus();
    this.setState({ query: "" });
    sleep(2000).then(() => {
      if (this.checkForRepetition(query)) {
        sendQueryToFirebase(
          "repetition",
          query,
          cleanQuery,
          Date.now(),
          this.props.uid,
          this.props.themeName
        );
      } else if (query !== "") {
        const intent = getIntent(query);
        sendQueryToFirebase(
          intent,
          query,
          cleanQuery,
          Date.now(),
          this.props.uid,
          this.props.themeName
        );
      }
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggle = (e) => {
    const queryBar = document.querySelector("#queryBar");
    const input = queryBar.querySelector("input");

    input.classList.toggle("queryBar-thick");
    input.classList.toggle("queryBar-thin");

    const container = queryBar.querySelector("div");
    container.classList.toggle("queryBarContainer-big");
    container.classList.toggle("queryBarContainer-small");
  };

  render() {
    const theme = themeMap[this.props.themeName];
    const { query } = this.state;
    return (
      <div style={{ marginTop: "50px" }}>
        <form id="queryBar" onSubmit={this.onSubmit}>
          <div className={"queryBarContainer-small"}>
            <button
              type="submit"
              style={{ display: "none" }}
              aria-label="submit"
            ></button>
            <input
              autoComplete="off"
              name="query"
              id="query-bar"
              type="text"
              placeholder="Enter your inquiry here"
              onChange={this.onChange}
              style={{
                textAlign: "center",
                borderBottomColor: theme["accentColor"],
              }}
              onFocus={this.toggle}
              onBlur={this.toggle}
              value={query}
              className={"queryBar-thin"}
            />
            <div className="userName-tip">
              {"You are: "}
              <span className={`${this.props.themeName}-font-color`}>
                {this.props.displayName || "Anonymous"}
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const QueryBarComponent = withFirebase(QueryBar);
export default QueryBarComponent;
