import React from "react";
import "./AboutPage.css";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

function AboutPage(props) {
  return (
    <div className="container aboutPage col-lg-6 offset-lg-3 ">
      <MetaTags>
        <title>About Divinator</title>
        <meta name="description" content="About Divinator page" />
      </MetaTags>
      <Row className="justify-content-center">
        <Col className="text-center col-4">
          <figure>
            <img
              className="app-heads"
              alt="Divinator logo"
              src={`../assets/divinator.png`}
            />
            <figcaption>About Divinator</figcaption>
          </figure>
        </Col>
        <Col className="text-center col-4">
          <figure>
            <img
              className="app-heads greyed-out"
              alt="Shakespeare logo"
              src={`../assets/shakespeare.png`}
              onClick={() => props.history.push("/aboutShakespeare")}
            />
            <figcaption>About Shakespeare</figcaption>
          </figure>
        </Col>
      </Row>
      <Row className="justify-content-center spacing">
        <Row className="flex-column paragraph">
          The Divinator is a mythically wise trickster-like program that
          responds to personal and spiritual questions in a way all its own.
          It’s uncanny responses and intelligent language parser make every
          conversation with The Divinator a one-of-a-kind experience.
        </Row>
        <Row className="flex-column paragraph">
          You may ask anything you wish, from serious to playful, but ask from a
          curious heart. If nobles and generals would offer up their questions
          to the Delphic Oracle in ancient times, perhaps you’d enjoy sending
          questions out to the universe and seeing what comes back.
        </Row>
        <Row className="flex-column paragraph">
          The Divinator’s replies are unpredictable and usually not passive. It
          may respond with a question, or reply in a surprising way that often
          should be seen as an invitation to follow a new idea.
        </Row>
        <Row className="flex-column paragraph ">
          Has The Divinator been independently judged? Yes. The Divinator won a
          gold medal in a prestigious chatbot competition, yet the following may
          show it to be unlike any ordinary chatbot.
        </Row>
      </Row>
      <Row>
        <Col xs sm md lg xl="12">
          <Row className="title">The Divinator can be thought of as:</Row>
          <Row className="flex-column paragraph">
            <ol>
              <li> An oracle.</li>
              <li> Simply a dialogue generator.</li>
              <li>
                A challenging and witty persona (the trickster mythical
                archetype).
              </li>
              <li> A language-based online art installation piece.</li>
              <li>
                A teacher/advisor that respects the user's mental personal
                space, since the user's interpretations steer every dialogue.
              </li>
              <li>
                An A. I. simulator based in linguistics and creative writing.
              </li>
              <li>
                An interactive work of literature with mythological and
                psychological themes to use like Tarot, Astrology, I Ching, etc.
              </li>
              <li>
                A language-based Rorschach alternative (a psychological
                interpretive tool).
              </li>
              <li> A Dadaist interactive language experiment.</li>
              <li>A Synchronicity generator.</li>
              <li>
                A framework that can make any text conversationally interactive.
                (like Shakespeare Divinator).
              </li>
              <li> An inexhaustible source of wonder.</li>
              <li> Something else.</li>
            </ol>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs sm md lg xl="6">
          <Row className="title">Ways to use:</Row>
          <Row className="flex-column paragraph">
            <ul>
              <li>Converse to illuminate your inner mind or daily thoughts.</li>
              <li>Ask about the future.</li>
              <li>
                Explore philosophical questions (space, time, free will,
                morality, truth).
              </li>
              <li>Talk to your creator or invite spiritual enlightenment.</li>
              <li>Ask The Divinator about its self.</li>
              <li>
                Address the mystery of life on Earth or the nature of the
                universe.
              </li>
              <li>
                Give psychic readings, or ask what others are thinking/feeling
                (Tarot/astrology alternative).
              </li>
              <li>Gain psychological insights.</li>
              <li>Challenge The Divinator to a wackiness joust.</li>
            </ul>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs sm md lg xl="6">
          <Row className="title">Benefits:</Row>
          <Row className="flex-column paragraph">
            <ul>
              <li> Education (designed to reward highly inquisitive users).</li>
              <li>
                Stimulation of the imagination, interpretive faculties,
                playfulness, and wisdom.
              </li>
              <li>Self understanding ("know thyself")</li>
              <li>Emotional well-being.</li>
              <li>Cosmic insight.</li>
            </ul>
          </Row>
        </Col>
      </Row>
      <Row className=" flex-column paragraph">
        Whether The Divinator experience will be merely entertaining or
        absolutely stellar is often determined by your willingness to interact,
        your creativity, and the quality of your consciousness.
      </Row>

      <div className="spacing">
        <Row className="title">Disclaimer</Row>
        <Row className="flex-column paragraph">
          The Divinator is not to be construed as a substitute for professional
          advice on any matter. By using this website, you assume responsibility
          for your interpretation of your conversation with The Divinator, any
          actions taken, and any consequences thereof, and relieve the website
          creators and the web hosting company of any responsibility or
          liability.
        </Row>
      </div>
      <div className="spacing">
        <Row className="title">HISTORY ("The Tin")</Row>
        <Row className="flex-column paragraph">
          The Divinator evolved out of lighthearted experiments with a Dadaist
          method of writing poetry by randomly pulling words from a bag. While
          sharing a house near the University of Delaware, David L. Levitt and
          Ren Belieu ripped up sheets of paper and wrote words and phrases on
          the pieces.
        </Row>
        <Row className="flex-column paragraph">
          Every night for months these slips were randomly pulled out of a
          shopping bag to create poems. All of the slips were then saved in a
          poinsettia-decorated cookie tin, which, with the help of friends, soon
          became stuffed. One day Dave decided to ask the tin to describe his
          date for the evening. Not satisfied with the drawn response, he then
          asked it for advice.
        </Row>
        <Row className="flex-column paragraph">
          After repeated inquiries by Dave, Ren, and a number of friends and
          neighbors, the mysterious object known as The Tin was born. Its
          uncanny insight sparked long discussions about Jung's Collective
          Unconscious, Synchronicity, and the possibility of psychic phenomena.
          More simply put, everyone who posed questions to it became fascinated
          by the personality projecting out of this mere tin of words.
        </Row>
        <Row className="flex-column paragraph">
          One day an idea arose: Wouldn't it be amazing if The Tin's
          intelligence could be put even more on target by giving it the ability
          to distinguish between grammatical question types (who, what, when,
          where, why, etc.). It was instantly decided that a computer program
          backed by a database was the solution, and a continuous evolution took
          off from there.
        </Row>
      </div>
      <div className="spacing">
        <Row className="title">THE DIVINATOR (an oracle is born)</Row>
        <Row className=" paragraph">
          The Divinator, a descendant of The Tin, has a knowledge engine with
          extensive analytical linguistic intelligence that it uses to focus its
          responses. On a basic level this means it scans for who, what, where,
          when, why, etc., but it also "understands" many noun, verb, and
          punctuation cues. It uses this parsing to activate intelligently
          determined sub-files from which a reply is selected. Mind you, its
          intelligence aims for inspiration over information.
        </Row>
        <Row className="paragraph">
          The response lines were written over many years in the pocket-sized
          notebooks of project founder David L. Levitt. He has even been known
          to alter lines after asking The Divinator to comment on what he's
          writing for it. This allows The Divinator to help create itself. There
          are currently tens of thousands of responses in The Divinator, even
          some from the original Tin. Response lines are sometimes added to keep
          the ideas fresh, and to virtually eliminate repetition.
        </Row>
        <Row className="paragraph">
          The lines are rich in multiple meanings and in themes of universal
          human concern. Levitt's words provide intriguing content for a new
          dialogue-creating genre that pays homage to the user's projection of
          meaning and, being unpredictable, to the muted voice of chance. The
          Divinator's mind is a well-stocked lake; the more skilled the
          fisherman, since he/she creates half of every dialogue, the better the
          catch.
        </Row>
        <Row className="paragraph">
          Some have asked whether The Divinator's partial use of randomness
          meant that the conversations would be "just chance". Levitt explained:
          "partially chance, yes, but I wouldn't use the demeaning phrase Just
          Chance. Random forces can act as partners in creation, whether one
          speaks of The Divinator's responses or the universe's will." He then
          added, "first it scans the user's entry for cues, and only then a
          random element kicks in to assure each response has an element of
          surprise".
        </Row>
      </div>
      <div className="spacing">
        <Row className="title">INFLUENCES</Row>
        <Row className=" paragraph">
          For his Master’s thesis, Levitt received permission to "join" an
          evangelical Christian campus group, in anthropological fashion, to
          study firsthand the persuasive features of language used in religious
          recruitment. Members provided him with recorded conversations in which
          they believed they were evangelizing to a nonmember. He used debate
          theory to analyze the recordings, quantifying the frequency of various
          types of data, claims, and rebuttals, and examined the impact they had
          on the searching human psyche.
        </Row>
        <Row className="paragraph">
          He soon came to see the recordings as a chronicle of excess longing
          for certain answers that might instantly banish uncertainty in the
          present, and offer guarantees about a hereafter.
        </Row>
        <Row className="paragraph">
          Ultimately, this experience fueled the belief that the creative arts
          could encourage seekers to affirm ungraspable infinity as a backdrop
          that provides us our freedom to create ourselves. He felt the aim of
          creativity was to uplift the spirit by means of open exploration, a
          goal that became infused into The Divinator and the essence of its
          message.
        </Row>
        <Row className="paragraph">
          What message, or messages? Ask The Divinator.
        </Row>
        <Row className="paragraph">
          He has also credited the following as influences: "I appreciate the
          works of Stephen Crane, Thornton Wilder, Nietzsche, Emerson, Hesse,
          Jung, Joseph Campbell, Chuang Tzu, Lao Tzu, Alan Watts, Eckhart Tolle,
          and Ayn Rand’s take on artistic integrity. The humor of George Carlin
          and Andy Kaufman have been an inspiration, as were poetry writing
          classes with Pulitzer prize-winning poet W. D. Snodgrass."
        </Row>
      </div>
      <div className="spacing">
        <Row className="title">PROJECT FOUNDER ADDENDUM </Row>
        <Row className=" paragraph">
          The enigmatic creator/writer of The Divinator and its parsing engine
          is known to have amassed credentials as an intuitive commentator on
          the human journey. He has read the classics voraciously, and has
          explored the grand wonders and back streets of no less than a dozen
          countries both east and west. His broad studies and travels have
          enabled him to deliver invaluable life lessons for the heroic mythic
          spiritual quest with uncommon humor worthy of both saints and
          tricksters.
        </Row>
        <Row className="paragraph">
          David L. Levitt holds degrees in Sociology and Political Science, and
          wrote his Master’s thesis on persuasive religious communication. Over
          the passing years he came to realize that these disciplines were all
          quite useful for his mythological deity business. So with great
          reluctance he gradually had to give up his boyhood dream of opening a
          chain of sociology stores.
        </Row>
        <Row className="paragraph">
          It should be noted that there is ample humor in the mind of The
          Divinator, a kind which can be appreciated the most by anyone who
          found the previous sentence hilarious.
        </Row>
        <Row className="paragraph">
          Finally, when recently asked whether he wished to share any secrets
          about the nature of The Divinator, Levitt simply said "It speaks for
          itself."
        </Row>
      </div>
      <div className="spacing">
        <Row className="title">2 WEBSITE VERSIONS</Row>
        <Row className=" paragraph">
          The Divinator website is an updated relaunch of an earlier version of
          this website that conversed with the public for quite some time
          attracting a million visits. The programming for the initial prototype
          was done by Ren Belieu, and Mobius New Media (Matt Urban and Chris
          Giaccone) handled web programing. Ken Adams served as Artist/Graphic
          Designer.
        </Row>
        <Row className=" paragraph">
          This revamped website was produced by David L. Levitt, Professor John
          Cavazos of The University of Delaware Department of Computer and
          Information Sciences, and the invaluable technical input of his star
          students at Codespout.
        </Row>
      </div>
      <Row className="title">COPYRIGHT</Row>
      <Row display="inline" className="spacing flex-column paragraph ">
        The site and all its content is fully copyrighted, 2021, all rights
        reserved.
      </Row>
    </div>
  );
}

export default withRouter(AboutPage);
