const white = "#FFFFFF";
const black = "#000000";

const themeDivinator = {
  body: black,
  accentColor: "darkOrange"
};

const themeShakespeare = {
  body: white,
  accentColor: "#76AEE0"
};

export const themeMap = {
  divinator: themeDivinator,
  shakespeare: themeShakespeare
};
const theme = mode => themeMap[mode];

export default theme;
