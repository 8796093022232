// Your web app's Firebase configuration
export const FirebaseConfig = {
  apiKey: "AIzaSyAknwPjEpiQEIXLX5RsMstGOImtb812UJY",
  authDomain: "dev-divinator.firebaseapp.com",
  databaseURL: "https://dev-divinator.firebaseio.com",
  projectId: "dev-divinator",
  storageBucket: "dev-divinator.appspot.com",
  messagingSenderId: "992898374717",
  appId: "1:992898374717:web:cf4c4ca4f24a90cb"
};
