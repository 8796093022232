import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import "./ContactPage.css";
import { sendContactMessage } from "../Firebase/functions";
import MetaTags from "react-meta-tags";

function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  let isDisabled = false === (name && email && message);

  let onSubmit = (e) => {
    e.preventDefault();
    if (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
      message !== ""
    ) {
      sendContactMessage(name, email, message);

      alert(
        `Thank you for contacting us!
      \n We will get back to you as soon as possible.
      \n The Divinator.`
      );
    }

    e.target.reset();
  };

  return (
    <Container id="container">
      <MetaTags>
        <title>Contact</title>
        <meta name="description" content="Contact page" />
      </MetaTags>
      <Row className="justify-content-md-center">
        <Form onSubmit={onSubmit}>
          <Row className="justify-content-md-center">
            <img
              src={require("../../images/logo.png")}
              alt="logo"
              className="mainLogo"
            />
          </Row>
          <Row className="justify-content-md-center">
            <h2>Contact Us!</h2>
          </Row>
          <Row>
            <Col>
              <Form.Group value={email}>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group value={name}>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group value={message}>
                <Form.Control
                  name="message"
                  type="text"
                  as="textarea"
                  rows="5"
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button disabled={isDisabled} id="submitButton" type="submit">
            Submit
          </Button>
        </Form>
      </Row>
    </Container>
  );
}

export default ContactPage;
