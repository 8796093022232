import React from "react";
import Conversation from "../Conversation/Conversation";
import QueryBar from "../QueryBar/QueryBar";

function ChatBox(props) {
  return (
    <div>
      <Conversation
        themeName={props.themeName}
        entityDisplayName={props.entityDisplayName}
        displayName={props.displayName}
        uid={props.uid}
      />
      <QueryBar
        themeName={props.themeName}
        entityDisplayName={props.entityDisplayName}
        displayName={props.displayName}
        uid={props.uid}
      />
    </div>
  );
}

export default ChatBox;
