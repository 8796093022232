import React from "react";
import WelcomePage from "../WelcomePage/WelcomePage";
import { AuthUserContext, withAuthentication } from "../Session";
import { withRouter } from "react-router-dom";

function HomePage(props) {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? props.history.push("/divinator") : <WelcomePage />
      }
    </AuthUserContext.Consumer>
  );
}

export default withRouter(withAuthentication(HomePage));
