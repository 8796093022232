const ignoreSet = new Set([
  "don't",
  "when",
  "about",
  "supposed",
  "this",
  "could",
  "dare",
  "does",
  "goes",
  "should",
  "know",
  "what",
  "where",
  "which",
  "how's",
  "since",
  "them",
  "I'll",
  "anybody",
  "anyone",
  "everybody",
  "everyone",
  "he'll",
  "he's",
  "it'll",
  "it's",
  "she'll",
  "she's",
  "somebody",
  "someone",
  "their",
  "these",
  "they",
  "they'll",
  "they're",
  "those",
  "we'll",
  "we're",
  "you're",
  "your",
  "yours",
  "what's",
  "another",
  "other",
  "others",
  "that",
  "they've",
  "we've",
  "were",
  "you've",
  "aren't",
  "weren't",
  "can't",
  "else",
  "have",
  "might",
  "shall",
  "shouldn't",
  "would",
  "wouldn't",
  "describe",
  "couldn't",
  "like",
  "didn't",
  "doesn't",
  "been",
  "hasn't",
  "haven't",
  "isn't",
  "must",
  "will",
  "won't",
  "seem",
  "really",
  "turn",
  "what'll",
  "means",
  "long",
  "soon",
  "when'll",
  "away",
  "from",
  "where's",
  "who'd",
  "who'll",
  "who's",
  "whom",
  "whos",
  "whose",
  "come",
  "why'd",
  "why's",
  "aint",
  "hadn't",
  "wasn't"
]);
export default ignoreSet;
