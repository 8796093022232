import React from "react";
import deityWelcomePage from "../../constants/deityWelcomePage";
import { Row, Col } from "react-bootstrap";

function DeityBar() {
  let colSize = 5;
  let bigTitleSize = 5;
  let smallTitleSize = 2;
  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col lg={colSize} xs={colSize}>
              <h4>{deityWelcomePage.divinator.name}</h4>
            </Col>
            <Col lg={smallTitleSize} xs={smallTitleSize}></Col>
            <Col lg={colSize} xs={colSize}>
              <h4>{deityWelcomePage.shakespeare.name}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={colSize} xs={colSize}>
              <img
                className="app-heads"
                alt="Divinator logo"
                src={`../assets/divinator.png`}
              />
            </Col>
            <Col lg={smallTitleSize} xs={smallTitleSize}></Col>
            <Col lg={colSize} xs={colSize}>
              <img
                className="app-heads"
                alt="Shakespeare logo"
                src={`../assets/shakespeare.png`}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={bigTitleSize} xs={bigTitleSize}>
              <div className="app-title">
                {deityWelcomePage.divinator.title}
              </div>
            </Col>
            <Col lg={smallTitleSize} xs={smallTitleSize}></Col>
            <Col lg={bigTitleSize} xs={bigTitleSize}>
              <div className="app-title">
                {deityWelcomePage.shakespeare.title}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default DeityBar;
