import { GET_SEND_QUERY_ROUTE } from "../../constants/functionsRoutes";

let sendQueryToFirebase = (
  intent,
  userQuery,
  filteredQuery,
  timestamp,
  uid,
  deity
) => {
  let route = GET_SEND_QUERY_ROUTE(
    intent,
    userQuery,
    filteredQuery,
    timestamp,
    uid,
    deity
  );

  fetch(route, {
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    if (response.status !== 200) {
      console.log(
        "got a bad response from sending the function",
        response.status
      );
    }
  });
};

let sendContactMessage = (name, fromEmail, message) => {
  let data = {
    name,
    fromEmail,
    message
  };

  fetch(
    "https://us-central1-dev-divinator.cloudfunctions.net/sendContactEmail",
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }
  ).then(res => console.log(res.json()));
};

export { sendQueryToFirebase, sendContactMessage };
