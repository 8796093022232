import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { Link, withRouter } from "react-router-dom";
import { Container, Col, Row, Form, Button } from "react-bootstrap";

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .createUser(email, passwordOne, username)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push("/");
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    return (
      <Container id="container">
        <Row className="justify-content-md-center">
          <Col md="6">
            <Row className="justify-content-md-center">
              <div className="contents">
                <img
                  src={require("../../images/logo.png")}
                  alt="logo"
                  className="mainLogo"
                />
                <Col md={{ span: 8, offset: 2 }} id="formColumn">
                  <Form onSubmit={this.onSubmit}>
                    <div className="usernameForm">
                      <Form.Group value={username}>
                        <Form.Control
                          name="username"
                          type="username"
                          placeholder="Username"
                          onChange={this.onChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="emailForm">
                      <Form.Group value={email}>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Email"
                          onChange={this.onChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="passwordForm">
                      <Form.Group value={passwordOne}>
                        <Form.Control
                          name="passwordOne"
                          type="password"
                          placeholder="Password"
                          onChange={this.onChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="passwordForm">
                      <Form.Group value={passwordTwo}>
                        <Form.Control
                          name="passwordTwo"
                          type="password"
                          placeholder="Password"
                          onChange={this.onChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="submitButtonHolder">
                      <Button id="signUpBtn" type="submit">
                        Create account
                      </Button>

                      <span style={{ color: "red" }}>
                        {error && <p>{error.message}</p>}
                      </span>
                      <p>
                        Already have an account?{" "}
                        <Link to={"/signin"}>Login</Link>
                      </p>
                    </div>
                  </Form>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
const SignUpForm = withFirebase(withRouter(SignUpFormBase));
export default SignUpForm;
