import React from "react";

/* Component import statements */
import LoginPage from "./components/LoginPage/LoginPage";
import ChatPage from "./components/ChatPage/ChatPage";
import SignUpPage from "./components/SignUpPage/SignUpPage";
import AboutDivinatorPage from "./components/AboutPage/AboutDivinatorPage";
import AboutShakespearePage from "./components/AboutPage/AboutShakespeare";
import ContactPage from "./components/ContactPage/ContactPage";
import NavigationBar from "./components/NavBar/NavBar";
import HomePage from "./components/HomePage/HomePage";

import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { withAuthentication } from "./components/Session/";
import WelcomePage from "./components/WelcomePage/WelcomePage";

const App = () => (
  <Router>
    <div className="App">
      <NavigationBar />
      <Switch>
        <Route path="/signin" component={LoginPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/" exact component={HomePage} />
        <Route path="/welcome" exact component={WelcomePage} />
        {/* <Route path="/chat" component={ChatPage} /> */}
        <Route path="/divinator" component={ChatPage} />
        <Route path="/shakespeare" component={ChatPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/aboutDivinator" component={AboutDivinatorPage} />
        <Route path="/aboutShakespeare" component={AboutShakespearePage} />

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  </Router>
);

export default withAuthentication(App);
