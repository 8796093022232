import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/database";
import { FirebaseConfig } from "./dev";

class Firebase {
  constructor() {
    app.initializeApp(FirebaseConfig);
    app.analytics();
    this.auth = app.auth();
    this.database = app.database();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signInAnonymously = () => this.auth.signInAnonymously();

  signInWithGoogle = () => this.auth.signInWithRedirect(this.googleProvider);

  signInWithFacebook = () =>
    this.auth.signInWithRedirect(this.facebookProvider);

  createUser = (email, password, username) =>
    this.auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        authUser.user.updateProfile({
          displayName: username,
        });
      });

  logOut = () => {
    this.auth.signOut();
  };

  getConversationRef = (uid, deity) => {
    return this.database.ref(`${deity}/conversations/${uid}`);
  };
}

export default Firebase;
