import React from "react";
import { withRouter } from "react-router-dom";

import "./Heads.css";

function FloatingHead({ size, image, themeFunc, name, currentTheme }) {
  return (
    <div
      className={
        (currentTheme !== name ? "greyed-out rest-head " : "focused-head") +
        " block-button"
      }
      onClick={themeFunc}
    >
      <img src={`${image}`} width={size} height={size} alt={name} />
    </div>
  );
}

let toggle = (themeName, history) => history.push("/" + themeName);

function Heads(props) {
  const themeName = props.themeName;

  return (
    <div className="float-heads">
      <FloatingHead
        name="divinator"
        size="100"
        currentTheme={themeName}
        image="../assets/divinator.png"
        themeFunc={() => toggle("divinator", props.history)}
      />
      <FloatingHead
        size="100"
        name="shakespeare"
        currentTheme={themeName}
        image="../assets/shakespeare.png"
        themeFunc={() => toggle("shakespeare", props.history)}
      />
    </div>
  );
}

export default withRouter(Heads);
