export default new Map([
  ["murder", "danger"],
  ["suicide", "danger"],
  ["homicide", "danger"],
  ["rape", "danger"],
  ["kill", "danger"],
  ["torture", "danger"],
  ["strangle", "danger"],
  ["kidnap", "danger"],
  ["suicidal", "danger"],
  ["homicidal", "danger"],
  ["rapist", "danger"],
  ["really feel", "what"],
  ["how focused", "how_many"],
  ["how awesome", "how_many"],
  ["how varied", "how_many"],
  ["how eclectic", "how_many"],
  ["when they've", "conversation"],
  ["when which", "conversation"],
  ["when somebody", "conversation"],
  ["when someone", "conversation"],
  ["when something", "conversation"],
  ["when that", "conversation"],
  ["when the", "conversation"],
  ["when these", "conversation"],
  ["when people", "conversation"],
  ["when they're", "conversation"],
  ["when others", "conversation"],
  ["when those", "conversation"],
  ["when was", "conversation"],
  ["when we", "conversation"],
  ["when were", "conversation"],
  ["when we're", "conversation"],
  ["when we've", "conversation"],
  ["when what", "conversation"],
  ["what your", "conversation"],
  ["when they", "conversation"],
  ["when everyone", "conversation"],
  ["what you're", "conversation"],
  ["what's up", "conversation"],
  ["when a", "conversation"],
  ["when another", "conversation"],
  ["when anybody", "conversation"],
  ["when anyone", "conversation"],
  ["when anything", "conversation"],
  ["when she", "conversation"],
  ["when everybody", "conversation"],
  ["when who", "conversation"],
  ["when everything", "conversation"],
  ["when he", "conversation"],
  ["when how", "conversation"],
  ["when I", "conversation"],
  ["when it", "conversation"],
  ["when it's", "conversation"],
  ["when no one", "conversation"],
  ["when nothing", "conversation"],
  ["when did", "conversation"],
  ["why not", "conversation"],
  ["when where", "conversation"],
  ["who were the", "conversation"],
  ["who weren't the", "conversation"],
  ["who what", "conversation"],
  ["who when", "conversation"],
  ["who where", "conversation"],
  ["who which", "conversation"],
  ["who how", "conversation"],
  ["why how", "conversation"],
  ["who aren't the", "conversation"],
  ["why what", "conversation"],
  ["why when", "conversation"],
  ["why where", "conversation"],
  ["why which", "conversation"],
  ["why who", "conversation"],
  ["you don't", "conversation"],
  ["you when", "conversation"],
  ["don't worry", "conversation"],
  ["who why", "conversation"],
  ["where why", "conversation"],
  ["when why", "conversation"],
  ["when you", "conversation"],
  ["when you're", "conversation"],
  ["when you've", "conversation"],
  ["where how", "conversation"],
  ["where what", "conversation"],
  ["where when", "conversation"],
  ["who me", "conversation"],
  ["where who", "conversation"],
  ["when nobody", "conversation"],
  ["which how", "conversation"],
  ["which what", "conversation"],
  ["which when", "conversation"],
  ["which where", "conversation"],
  ["which who", "conversation"],
  ["which why", "conversation"],
  ["who are the", "conversation"],
  ["who are they", "conversation"],
  ["where which", "conversation"],
  ["what about you", "conversation"],
  ["how who", "conversation"],
  ["what you", "conversation"],
  ["how's it", "conversation"],
  ["it when", "conversation"],
  ["me when", "conversation"],
  ["one who", "conversation"],
  ["since when", "conversation"],
  ["what her", "conversation"],
  ["us when", "conversation"],
  ["how when", "conversation"],
  ["what amount", "conversation"],
  ["what anybody", "conversation"],
  ["what anyone", "conversation"],
  ["what everybody", "conversation"],
  ["what everyone", "conversation"],
  ["what flavor", "conversation"],
  ["what he", "conversation"],
  ["what he'll", "conversation"],
  ["them when", "conversation"],
  ["how could you", "conversation"],
  ["favorite flavor", "conversation"],
  ["favorite number", "conversation"],
  ["go wrong", "conversation"],
  ["her when", "conversation"],
  ["him when", "conversation"],
  ["how about", "conversation"],
  ["how am I supposed", "conversation"],
  ["when other", "conversation"],
  ["how where", "conversation"],
  ["how did you feel", "conversation"],
  ["how do you do", "conversation"],
  ["how do you feel", "conversation"],
  ["how goes", "conversation"],
  ["how should I know", "conversation"],
  ["how was I supposed", "conversation"],
  ["how what", "conversation"],
  ["how why", "conversation"],
  ["how can this be", "conversation"],
  ["what we", "conversation"],
  ["what somebody", "conversation"],
  ["what someone", "conversation"],
  ["what the", "conversation"],
  ["what their", "conversation"],
  ["what these", "conversation"],
  ["what they", "conversation"],
  ["what they'll", "conversation"],
  ["what they're", "conversation"],
  ["what size", "conversation"],
  ["what time", "conversation"],
  ["what who", "conversation"],
  ["what we'll", "conversation"],
  ["what we're", "conversation"],
  ["what when", "conversation"],
  ["what where", "conversation"],
  ["what which", "conversation"],
  ["what he's", "conversation"],
  ["what why", "conversation"],
  ["how which", "conversation"],
  ["what those", "conversation"],
  ["what I", "conversation"],
  ["what how", "conversation"],
  ["what I'll", "conversation"],
  ["what I'm", "conversation"],
  ["what it", "conversation"],
  ["what it'll", "conversation"],
  ["what it's", "conversation"],
  ["what my", "conversation"],
  ["what no one", "conversation"],
  ["what nobody", "conversation"],
  ["what our", "conversation"],
  ["what percent", "conversation"],
  ["what percentage", "conversation"],
  ["what quantity", "conversation"],
  ["what she", "conversation"],
  ["what she'll", "conversation"],
  ["what she's", "conversation"],
  ["what his", "conversation"],
  ["what number", "conversation"],
  ["how would it like", "what"],
  ["how were", "what"],
  ["how will", "what"],
  ["how won't", "what"],
  ["how would he feel", "what"],
  ["how would he like", "what"],
  ["how was", "what"],
  ["how would it feel", "what"],
  ["how should I feel", "what"],
  ["how would I feel", "what"],
  ["how should you feel", "what"],
  ["how should we feel", "what"],
  ["how should they feel", "what"],
  ["how should it feel", "what"],
  ["how should he feel", "what"],
  ["how must", "what"],
  ["how would she feel", "what"],
  ["how isn't", "what"],
  ["it sound", "what"],
  ["how should she feel", "what"],
  ["it smell", "what"],
  ["it taste", "what"],
  ["which means", "what"],
  ["what's", "what"],
  ["what'll", "what"],
  ["what", "what"],
  ["we behave", "what"],
  ["turn out", "what"],
  ["it seem", "what"],
  ["how is", "what"],
  ["how would she like", "what"],
  ["which means what", "what"],
  ["it look", "what"],
  ["I behave", "what"],
  ["how would you like", "what"],
  ["how would you feel", "what"],
  ["how would we feel", "what"],
  ["how would they like", "what"],
  ["how would they feel", "what"],
  ["she behave", "what"],
  ["how haven't", "what"],
  ["how did you like", "what"],
  ["how did we feel", "what"],
  ["how did they like", "what"],
  ["how have you been", "what"],
  ["how dirty", "how_many"],
  ["how did they feel", "what"],
  ["how did she like", "what"],
  ["how did she feel", "what"],
  ["how did it like", "what"],
  ["how didn't", "what"],
  ["how did I feel", "what"],
  ["they behave", "what"],
  ["how did he like", "what"],
  ["how did he feel", "what"],
  ["how aren't", "what"],
  ["how are", "what"],
  ["how am", "what"],
  ["he behave", "what"],
  ["future look", "what"],
  ["describe", "what"],
  ["how did it feel", "what"],
  ["how does she like", "what"],
  ["how have they been", "what"],
  ["how have I been", "what"],
  ["how hasn't", "what"],
  ["how has she been", "what"],
  ["how has it been", "what"],
  ["how couldn't", "what"],
  ["how has he been", "what"],
  ["how have we been", "what"],
  ["how do I feel", "what"],
  ["how doesn't", "what"],
  ["how does she feel", "what"],
  ["how does it like", "what"],
  ["how does it feel", "what"],
  ["how does he like", "what"],
  ["how does he feel", "what"],
  ["how do you like", "what"],
  ["how do we feel", "what"],
  ["how do they like", "what"],
  ["how do they feel", "what"],
  ["how don't", "what"],
  ["when", "when"],
  ["when'll", "when"],
  ["how long will", "when"],
  ["how soon will", "when"],
  ["how far from", "where"],
  ["where", "where"],
  ["where's", "where"],
  ["how far away", "where"],
  ["which", "which"],
  ["whose", "who"],
  ["who", "who"],
  ["who'd", "who"],
  ["who'll", "who"],
  ["whom", "who"],
  ["who's", "who"],
  ["why's", "why"],
  ["why'd", "why"],
  ["why", "why"],
  ["at at at", "why"],
  ["how come", "why"],
  ["how have", "how"],
  ["how wouldn't", "how"],
  ["how would", "how"],
  ["how shouldn't", "how"],
  ["how should", "how"],
  ["how shall", "how"],
  ["how might", "how"],
  ["how may", "how"],
  ["how can", "how"],
  ["how has", "how"],
  ["how can't", "how"],
  ["how could", "how"],
  ["how did", "how"],
  ["how do", "how"],
  ["how does", "how"],
  ["how else", "how"],
  ["how had", "how"],
  ["how encouraged", "how_many"],
  ["how exquisite", "how_many"],
  ["how elusive", "how_many"],
  ["how emphatic", "how_many"],
  ["how emotionally", "how_many"],
  ["how emotional", "how_many"],
  ["how embellished", "how_many"],
  ["how embarrassing", "how_many"],
  ["how embarrassed", "how_many"],
  ["how empty", "how_many"],
  ["how elegant", "how_many"],
  ["how effortless", "how_many"],
  ["how encouraging", "how_many"],
  ["how efficient", "how_many"],
  ["how dependent", "how_many"],
  ["how effective", "how_many"],
  ["how ecstatic", "how_many"],
  ["how economical", "how_many"],
  ["how easy", "how_many"],
  ["how easily", "how_many"],
  ["how eager", "how_many"],
  ["how explosive", "how_many"],
  ["how ethereal", "how_many"],
  ["how dumb", "how_many"],
  ["how experimental", "how_many"],
  ["how experienced", "how_many"],
  ["how expensive", "how_many"],
  ["how expansive", "how_many"],
  ["how exotic", "how_many"],
  ["how exhausting", "how_many"],
  ["how exhausted", "how_many"],
  ["how exclusive", "how_many"],
  ["how enjoyable", "how_many"],
  ["how exasperated", "how_many"],
  ["how energetic", "how_many"],
  ["how essential", "how_many"],
  ["how erroneous", "how_many"],
  ["how erratic", "how_many"],
  ["how envious", "how_many"],
  ["how enormous", "how_many"],
  ["how enlightening", "how_many"],
  ["how enlightened", "how_many"],
  ["how enigmatic", "how_many"],
  ["how energetically", "how_many"],
  ["how excessive", "how_many"],
  ["how extreme", "how_many"],
  ["how deprived", "how_many"],
  ["how difficult", "how_many"],
  ["how different", "how_many"],
  ["how devout", "how_many"],
  ["how devious", "how_many"],
  ["how devilish", "how_many"],
  ["how developed", "how_many"],
  ["how determined", "how_many"],
  ["how destructive", "how_many"],
  ["how direct", "how_many"],
  ["how desirable", "how_many"],
  ["how directly", "how_many"],
  ["how depraved", "how_many"],
  ["how free", "how_many"],
  ["how demented", "how_many"],
  ["how delusional", "how_many"],
  ["how delightful", "how_many"],
  ["how delicious", "how_many"],
  ["how deleterious", "how_many"],
  ["how degenerate", "how_many"],
  ["how definite", "how_many"],
  ["how deficient", "how_many"],
  ["how desperate", "how_many"],
  ["how dissatisfied", "how_many"],
  ["how drunk", "how_many"],
  ["how drastic", "how_many"],
  ["how dramatic", "how_many"],
  ["how divine", "how_many"],
  ["how divided", "how_many"],
  ["how diverse", "how_many"],
  ["how distracting", "how_many"],
  ["how distracted", "how_many"],
  ["how distorted", "how_many"],
  ["how dim", "how_many"],
  ["how dissonant", "how_many"],
  ["how dubious", "how_many"],
  ["how disoriented", "how_many"],
  ["how dishonest", "how_many"],
  ["how disfunctional", "how_many"],
  ["how discriminating", "how_many"],
  ["how discoursive", "how_many"],
  ["how discouraging", "how_many"],
  ["how discouraged", "how_many"],
  ["how disconnected", "how_many"],
  ["how disappointing", "how_many"],
  ["how disappointed", "how_many"],
  ["how distinctive", "how_many"],
  ["how futile", "how_many"],
  ["how godlike", "how_many"],
  ["how glorious", "how_many"],
  ["how giving", "how_many"],
  ["how ghastly", "how_many"],
  ["how genuine", "how_many"],
  ["how gently", "how_many"],
  ["how gentle", "how_many"],
  ["how generous", "how_many"],
  ["how gay", "how_many"],
  ["how fucked", "how_many"],
  ["how futuristic", "how_many"],
  ["how gracious", "how_many"],
  ["how fussy", "how_many"],
  ["how furry", "how_many"],
  ["how furious", "how_many"],
  ["how funny", "how_many"],
  ["how funky", "how_many"],
  ["how fun", "how_many"],
  ["how full", "how_many"],
  ["how fucking", "how_many"],
  ["how fragmented", "how_many"],
  ["how fuzzy", "how_many"],
  ["how happy", "how_many"],
  ["how asleep", "how_many"],
  ["how defensive", "how_many"],
  ["how helpful", "how_many"],
  ["how heavenly", "how_many"],
  ["how healthy", "how_many"],
  ["how headstrong", "how_many"],
  ["how hateful", "how_many"],
  ["how harsh", "how_many"],
  ["how harmonious", "how_many"],
  ["how good", "how_many"],
  ["how hard", "how_many"],
  ["how goodly", "how_many"],
  ["how handsome", "how_many"],
  ["how hallucinogenic", "how_many"],
  ["how half-hearted", "how_many"],
  ["how guilty", "how_many"],
  ["how guarded", "how_many"],
  ["how zealous", "how_many"],
  ["how great", "how_many"],
  ["how grateful", "how_many"],
  ["how graphic", "how_many"],
  ["how frustrating", "how_many"],
  ["how harmful", "how_many"],
  ["how favorable", "how_many"],
  ["how fit", "how_many"],
  ["how fine", "how_many"],
  ["how final", "how_many"],
  ["how filthy", "how_many"],
  ["how fiery", "how_many"],
  ["how fierce", "how_many"],
  ["how fibrous", "how_many"],
  ["how feverous", "how_many"],
  ["how fertile", "how_many"],
  ["how fuckin", "how_many"],
  ["how fearful", "how_many"],
  ["how flexible", "how_many"],
  ["how fat", "how_many"],
  ["how fastidious", "how_many"],
  ["how fast", "how_many"],
  ["how far", "how_many"],
  ["how fantastic", "how_many"],
  ["how fanatical", "how_many"],
  ["how famous", "how_many"],
  ["how familiar", "how_many"],
  ["how faithful", "how_many"],
  ["how feminine", "how_many"],
  ["how formidable", "how_many"],
  ["how frustrated", "how_many"],
  ["how frivolous", "how_many"],
  ["how frigid", "how_many"],
  ["how frightening", "how_many"],
  ["how frightened", "how_many"],
  ["how friendly", "how_many"],
  ["how freely", "how_many"],
  ["how frantic", "how_many"],
  ["how fragile", "how_many"],
  ["how fitting", "how_many"],
  ["how fortuitous", "how_many"],
  ["how fixated", "how_many"],
  ["how formally", "how_many"],
  ["how formal", "how_many"],
  ["how forgiving", "how_many"],
  ["how forgiven", "how_many"],
  ["how forgetful", "how_many"],
  ["how forceful", "how_many"],
  ["how forbidden", "how_many"],
  ["how foolish", "how_many"],
  ["how flirtatious", "how_many"],
  ["how exuberant", "how_many"],
  ["how fortunate", "how_many"],
  ["how authoritative", "how_many"],
  ["how barbarous", "how_many"],
  ["how balanced", "how_many"],
  ["how baggy", "how_many"],
  ["how bad", "how_many"],
  ["how backward", "how_many"],
  ["how awkward", "how_many"],
  ["how awful", "how_many"],
  ["how aware", "how_many"],
  ["how awake", "how_many"],
  ["how ashamed", "how_many"],
  ["how autonomous", "how_many"],
  ["how beneficial", "how_many"],
  ["how auspicious", "how_many"],
  ["how attractive", "how_many"],
  ["how attentive", "how_many"],
  ["how astounding", "how_many"],
  ["how astounded", "how_many"],
  ["how astonishing", "how_many"],
  ["how helpless", "how_many"],
  ["how assertive", "how_many"],
  ["how astonished", "how_many"],
  ["how average", "how_many"],
  ["how blasphemous", "how_many"],
  ["how boring", "how_many"],
  ["how bored", "how_many"],
  ["how bold", "how_many"],
  ["how blurry", "how_many"],
  ["how blunt", "how_many"],
  ["how blue", "how_many"],
  ["how bloody", "how_many"],
  ["how blissful", "how_many"],
  ["how blind", "how_many"],
  ["how beauteous", "how_many"],
  ["how bleak", "how_many"],
  ["how beautiful", "how_many"],
  ["how blank", "how_many"],
  ["how black", "how_many"],
  ["how bizarre", "how_many"],
  ["how bitter", "how_many"],
  ["how bitchy", "how_many"],
  ["how bisexual", "how_many"],
  ["how big", "how_many"],
  ["how berserk", "how_many"],
  ["how benevolent", "how_many"],
  ["how artsy", "how_many"],
  ["how blessed", "how_many"],
  ["how acute", "how_many"],
  ["how alert", "how_many"],
  ["how aggressive", "how_many"],
  ["how aggravating", "how_many"],
  ["how aggravated", "how_many"],
  ["how afraid", "how_many"],
  ["how affirmative", "how_many"],
  ["how advisable", "how_many"],
  ["how adventurous", "how_many"],
  ["how advanced", "how_many"],
  ["how gregarious", "how_many"],
  ["how admirable", "how_many"],
  ["how amazed", "how_many"],
  ["how actively", "how_many"],
  ["how active", "how_many"],
  ["how accurate", "how_many"],
  ["how abusive", "how_many"],
  ["how abused", "how_many"],
  ["how abundant", "how_many"],
  ["how absurd", "how_many"],
  ["how abstract", "how_many"],
  ["how abnormal", "how_many"],
  ["how adorable", "how_many"],
  ["how anxious", "how_many"],
  ["how artistic", "how_many"],
  ["how artificial", "how_many"],
  ["how articulate", "how_many"],
  ["how arrogant", "how_many"],
  ["how arousing", "how_many"],
  ["how aroused", "how_many"],
  ["how arduous", "how_many"],
  ["how appropriate", "how_many"],
  ["how appealing", "how_many"],
  ["how alive", "how_many"],
  ["how appalled", "how_many"],
  ["how alone", "how_many"],
  ["how anonymous", "how_many"],
  ["how anomalous", "how_many"],
  ["how annoying", "how_many"],
  ["how annoyed", "how_many"],
  ["how angry", "how_many"],
  ["how ambivalent", "how_many"],
  ["how ambitious", "how_many"],
  ["how ambiguous", "how_many"],
  ["how amazing", "how_many"],
  ["how brilliant", "how_many"],
  ["how appalling", "how_many"],
  ["how controversial", "how_many"],
  ["how crappy", "how_many"],
  ["how coy", "how_many"],
  ["how cowardly", "how_many"],
  ["how courageous", "how_many"],
  ["how counterproductive", "how_many"],
  ["how costly", "how_many"],
  ["how cosmic", "how_many"],
  ["how corrupt", "how_many"],
  ["how cooperative", "how_many"],
  ["how brave", "how_many"],
  ["how convenient", "how_many"],
  ["how credible", "how_many"],
  ["how controlling", "how_many"],
  ["how controlled", "how_many"],
  ["how content", "how_many"],
  ["how contemporary", "how_many"],
  ["how contageous", "how_many"],
  ["how constrictive", "how_many"],
  ["how constricted", "how_many"],
  ["how constrained", "how_many"],
  ["how conspicuous", "how_many"],
  ["how cool", "how_many"],
  ["how dark", "how_many"],
  ["how deep", "how_many"],
  ["how deductive", "how_many"],
  ["how decisive", "how_many"],
  ["how deceptive", "how_many"],
  ["how deceiving", "how_many"],
  ["how deceived", "how_many"],
  ["how decadent", "how_many"],
  ["how deaf", "how_many"],
  ["how dead", "how_many"],
  ["how crazy", "how_many"],
  ["how dazed", "how_many"],
  ["how creative", "how_many"],
  ["how daring", "how_many"],
  ["how dangerous", "how_many"],
  ["how dainty", "how_many"],
  ["how cynical", "how_many"],
  ["how cursed", "how_many"],
  ["how curious", "how_many"],
  ["how crushed", "how_many"],
  ["how cruel", "how_many"],
  ["how credulous", "how_many"],
  ["how conscious", "how_many"],
  ["how dazzling", "how_many"],
  ["how cautious", "how_many"],
  ["how considerate", "how_many"],
  ["how circular", "how_many"],
  ["how chivalrous", "how_many"],
  ["how chic", "how_many"],
  ["how charming", "how_many"],
  ["how charitable", "how_many"],
  ["how charismatic", "how_many"],
  ["how chaotic", "how_many"],
  ["how challenging", "how_many"],
  ["how clean", "how_many"],
  ["how cavernous", "how_many"],
  ["how clear", "how_many"],
  ["how carefully", "how_many"],
  ["how careful", "how_many"],
  ["how calm", "how_many"],
  ["how callous", "how_many"],
  ["how buzzy", "how_many"],
  ["how busy", "how_many"],
  ["how brutal", "how_many"],
  ["how brittle", "how_many"],
  ["how brisk", "how_many"],
  ["how defective", "how_many"],
  ["how certain", "how_many"],
  ["how competent", "how_many"],
  ["how bright", "how_many"],
  ["how confused", "how_many"],
  ["how confidently", "how_many"],
  ["how confident", "how_many"],
  ["how concerned", "how_many"],
  ["how compulsive", "how_many"],
  ["how comprehensive", "how_many"],
  ["how complicated", "how_many"],
  ["how complex", "how_many"],
  ["how clamorous", "how_many"],
  ["how competitive", "how_many"],
  ["how conservative", "how_many"],
  ["how commonplace", "how_many"],
  ["how common", "how_many"],
  ["how comfortable", "how_many"],
  ["how colorful", "how_many"],
  ["how cold", "how_many"],
  ["how coincidental", "how_many"],
  ["how cohesive", "how_many"],
  ["how coherent", "how_many"],
  ["how close", "how_many"],
  ["how clever", "how_many"],
  ["how complete", "how_many"],
  ["how sinful", "how_many"],
  ["how real", "how_many"],
  ["how shy", "how_many"],
  ["how sick", "how_many"],
  ["how sickening", "how_many"],
  ["how significant", "how_many"],
  ["how silent", "how_many"],
  ["how silly", "how_many"],
  ["how shocking", "how_many"],
  ["how simple", "how_many"],
  ["how shocked", "how_many"],
  ["how skeptical", "how_many"],
  ["how skilled", "how_many"],
  ["how skillful", "how_many"],
  ["how skinny", "how_many"],
  ["how sleepy", "how_many"],
  ["how slick", "how_many"],
  ["how slim", "how_many"],
  ["how slimey", "how_many"],
  ["how similar", "how_many"],
  ["how sexy", "how_many"],
  ["how sensitive", "how_many"],
  ["how sensual", "how_many"],
  ["how sentimental", "how_many"],
  ["how separate", "how_many"],
  ["how serious", "how_many"],
  ["how seriously", "how_many"],
  ["how severe", "how_many"],
  ["how short", "how_many"],
  ["how sexually", "how_many"],
  ["how slow", "how_many"],
  ["how shadowy", "how_many"],
  ["how shady", "how_many"],
  ["how shallow", "how_many"],
  ["how shameful", "how_many"],
  ["how shameless", "how_many"],
  ["how shapely", "how_many"],
  ["how sharp", "how_many"],
  ["how shitty", "how_many"],
  ["how sexual", "how_many"],
  ["how stoic", "how_many"],
  ["how spiritually", "how_many"],
  ["how spontaneous", "how_many"],
  ["how sportive", "how_many"],
  ["how stable", "how_many"],
  ["how stalwart", "how_many"],
  ["how startled", "how_many"],
  ["how startling", "how_many"],
  ["how slippery", "how_many"],
  ["how stimulating", "how_many"],
  ["how specific", "how_many"],
  ["how stoned", "how_many"],
  ["how stormy", "how_many"],
  ["how strange", "how_many"],
  ["how strategic", "how_many"],
  ["how stressed", "how_many"],
  ["how stressful", "how_many"],
  ["how strict", "how_many"],
  ["how strong", "how_many"],
  ["how steady", "how_many"],
  ["how sore", "how_many"],
  ["how self-righteous", "how_many"],
  ["how slowly", "how_many"],
  ["how small", "how_many"],
  ["how smart", "how_many"],
  ["how smooth", "how_many"],
  ["how social", "how_many"],
  ["how soft", "how_many"],
  ["how solitary", "how_many"],
  ["how spiritual", "how_many"],
  ["how sophisticated", "how_many"],
  ["how speculative", "how_many"],
  ["how sorrowful", "how_many"],
  ["how sorry", "how_many"],
  ["how soulful", "how_many"],
  ["how sour", "how_many"],
  ["how spaced", "how_many"],
  ["how spacious", "how_many"],
  ["how special", "how_many"],
  ["how sloppy", "how_many"],
  ["how soothing", "how_many"],
  ["how revolting", "how_many"],
  ["how resentful", "how_many"],
  ["how reserved", "how_many"],
  ["how resistant", "how_many"],
  ["how respected", "how_many"],
  ["how respectful", "how_many"],
  ["how responsible", "how_many"],
  ["how restrained", "how_many"],
  ["how rigorous", "how_many"],
  ["how retarded", "how_many"],
  ["how repressive", "how_many"],
  ["how revolutionary", "how_many"],
  ["how rewarding", "how_many"],
  ["how rhetorical", "how_many"],
  ["how rhythmic", "how_many"],
  ["how rich", "how_many"],
  ["how ridiculous", "how_many"],
  ["how right", "how_many"],
  ["how senile", "how_many"],
  ["how restrictive", "how_many"],
  ["how reluctant", "how_many"],
  ["how realistic", "how_many"],
  ["how rebellious", "how_many"],
  ["how receptive", "how_many"],
  ["how reckless", "how_many"],
  ["how refined", "how_many"],
  ["how regretful", "how_many"],
  ["how regrettable", "how_many"],
  ["how reliable", "how_many"],
  ["how repugnant", "how_many"],
  ["how religious", "how_many"],
  ["how republican", "how_many"],
  ["how remorseful", "how_many"],
  ["how renowned", "how_many"],
  ["how repellent", "how_many"],
  ["how repentant", "how_many"],
  ["how heroic", "how_many"],
  ["how reprehensible", "how_many"],
  ["how grave", "how_many"],
  ["how risky", "how_many"],
  ["how relieved", "how_many"],
  ["how seductive", "how_many"],
  ["how savage", "how_many"],
  ["how saved", "how_many"],
  ["how scared", "how_many"],
  ["how scary", "how_many"],
  ["how scientific", "how_many"],
  ["how secluded", "how_many"],
  ["how secret", "how_many"],
  ["how rigid", "how_many"],
  ["how secure", "how_many"],
  ["how satanic", "how_many"],
  ["how selective", "how_many"],
  ["how self-assured", "how_many"],
  ["how self-centered", "how_many"],
  ["how self-confident", "how_many"],
  ["how self-conscious", "how_many"],
  ["how selfish", "how_many"],
  ["how self-reliant", "how_many"],
  ["how stunned", "how_many"],
  ["how secretive", "how_many"],
  ["how safe", "how_many"],
  ["how robotic", "how_many"],
  ["how romantic", "how_many"],
  ["how rough", "how_many"],
  ["how royal", "how_many"],
  ["how rubbery", "how_many"],
  ["how rude", "how_many"],
  ["how ruinous", "how_many"],
  ["how sacred", "how_many"],
  ["how satisfying", "how_many"],
  ["how sad", "how_many"],
  ["how satisfied", "how_many"],
  ["how salacious", "how_many"],
  ["how salubrious", "how_many"],
  ["how sanctimonious", "how_many"],
  ["how sane", "how_many"],
  ["how sarcastic", "how_many"],
  ["how sardonic", "how_many"],
  ["how sassy", "how_many"],
  ["how self-serving", "how_many"],
  ["how sacrilegious", "how_many"],
  ["how unusual", "how_many"],
  ["how unnatural", "how_many"],
  ["how unreal", "how_many"],
  ["how unreasonable", "how_many"],
  ["how unreliable", "how_many"],
  ["how unruly", "how_many"],
  ["how unstable", "how_many"],
  ["how unstructured", "how_many"],
  ["how valuable", "how_many"],
  ["how unthinkable", "how_many"],
  ["how unknown", "how_many"],
  ["how unworthy", "how_many"],
  ["how upset", "how_many"],
  ["how upstanding", "how_many"],
  ["how urgent", "how_many"],
  ["how useful", "how_many"],
  ["how useless", "how_many"],
  ["how vacuous", "how_many"],
  ["how uncomfortable", "how_many"],
  ["how untamed", "how_many"],
  ["how unfriendly", "how_many"],
  ["how stubborn", "how_many"],
  ["how uncooperative", "how_many"],
  ["how undaunted", "how_many"],
  ["how understanding", "how_many"],
  ["how uneasy", "how_many"],
  ["how unfair", "how_many"],
  ["how unfairly", "how_many"],
  ["how unfettered", "how_many"],
  ["how unlucky", "how_many"],
  ["how unfortunate", "how_many"],
  ["how unlikely", "how_many"],
  ["how unified", "how_many"],
  ["how unique", "how_many"],
  ["how united", "how_many"],
  ["how universal", "how_many"],
  ["how universally", "how_many"],
  ["how unjust", "how_many"],
  ["how unknowable", "how_many"],
  ["how vane", "how_many"],
  ["how unfocused", "how_many"],
  ["how wonderful", "how_many"],
  ["how weird", "how_many"],
  ["how well", "how_many"],
  ["how wet", "how_many"],
  ["how wicked", "how_many"],
  ["how wide", "how_many"],
  ["how widespread", "how_many"],
  ["how wild", "how_many"],
  ["how valid", "how_many"],
  ["how witty", "how_many"],
  ["how wasted", "how_many"],
  ["how wondrous", "how_many"],
  ["how wordy", "how_many"],
  ["how worldly", "how_many"],
  ["how worried", "how_many"],
  ["how worthwhile", "how_many"],
  ["how worthy", "how_many"],
  ["how wrong", "how_many"],
  ["how young", "how_many"],
  ["how wise", "how_many"],
  ["how vivid", "how_many"],
  ["how vast", "how_many"],
  ["how venomous", "how_many"],
  ["how versatile", "how_many"],
  ["how vicious", "how_many"],
  ["how vigorous", "how_many"],
  ["how vile", "how_many"],
  ["how violent", "how_many"],
  ["how virile", "how_many"],
  ["how wealthy", "how_many"],
  ["how visionary", "how_many"],
  ["how weak", "how_many"],
  ["how voluminous", "how_many"],
  ["how voluptuous", "how_many"],
  ["how vulnerable", "how_many"],
  ["how wacky", "how_many"],
  ["how warm", "how_many"],
  ["how warped", "how_many"],
  ["how wary", "how_many"],
  ["how unclear", "how_many"],
  ["how virtuous", "how_many"],
  ["how technical", "how_many"],
  ["how surrealistic", "how_many"],
  ["how suspicious", "how_many"],
  ["how sweet", "how_many"],
  ["how symbolic", "how_many"],
  ["how talented", "how_many"],
  ["how talkative", "how_many"],
  ["how tall", "how_many"],
  ["how tentative", "how_many"],
  ["how tantalizing", "how_many"],
  ["how surprised", "how_many"],
  ["how technically", "how_many"],
  ["how tedious", "how_many"],
  ["how telepathic", "how_many"],
  ["how tempermental", "how_many"],
  ["how tempted", "how_many"],
  ["how tempting", "how_many"],
  ["how tender", "how_many"],
  ["how uncommon", "how_many"],
  ["how tangled", "how_many"],
  ["how suggestive", "how_many"],
  ["how repetitive", "how_many"],
  ["how stunning", "how_many"],
  ["how stupid", "how_many"],
  ["how subjective", "how_many"],
  ["how sublime", "how_many"],
  ["how submissive", "how_many"],
  ["how subservient", "how_many"],
  ["how subtle", "how_many"],
  ["how surreal", "how_many"],
  ["how suggestible", "how_many"],
  ["how surprising", "how_many"],
  ["how suicidal", "how_many"],
  ["how superficial", "how_many"],
  ["how superfluous", "how_many"],
  ["how supernatural", "how_many"],
  ["how supportive", "how_many"],
  ["how supreme", "how_many"],
  ["how sure", "how_many"],
  ["how terrible", "how_many"],
  ["how successful", "how_many"],
  ["how truly", "how_many"],
  ["how tragic", "how_many"],
  ["how transcendent", "how_many"],
  ["how transient", "how_many"],
  ["how transparent", "how_many"],
  ["how trapped", "how_many"],
  ["how traumatic", "how_many"],
  ["how tremendous", "how_many"],
  ["how tense", "how_many"],
  ["how true", "how_many"],
  ["how touching", "how_many"],
  ["how truthful", "how_many"],
  ["how twisted", "how_many"],
  ["how tyrannous", "how_many"],
  ["how ugly", "how_many"],
  ["how unappreciated", "how_many"],
  ["how unbearable", "how_many"],
  ["how uncaring", "how_many"],
  ["how uncertain", "how_many"],
  ["how tricky", "how_many"],
  ["how thunderous", "how_many"],
  ["how terse", "how_many"],
  ["how thankful", "how_many"],
  ["how therapeutic", "how_many"],
  ["how thin", "how_many"],
  ["how thirsty", "how_many"],
  ["how thorough", "how_many"],
  ["how thoughtful", "how_many"],
  ["how thoughtless", "how_many"],
  ["how traditional", "how_many"],
  ["how threatening", "how_many"],
  ["how tough", "how_many"],
  ["how ticklish", "how_many"],
  ["how tight", "how_many"],
  ["how timeless", "how_many"],
  ["how tired", "how_many"],
  ["how tiring", "how_many"],
  ["how tolerant", "how_many"],
  ["how touched", "how_many"],
  ["how studious", "how_many"],
  ["how threatened", "how_many"],
  ["how massive", "how_many"],
  ["how magnanimous", "how_many"],
  ["how majestic", "how_many"],
  ["how malicious", "how_many"],
  ["how manipulated", "how_many"],
  ["how manipulative", "how_many"],
  ["how manly", "how_many"],
  ["how many", "how_many"],
  ["how memorable", "how_many"],
  ["how masculine", "how_many"],
  ["how lusty", "how_many"],
  ["how masterful", "how_many"],
  ["how materialistic", "how_many"],
  ["how mature", "how_many"],
  ["how mean", "how_many"],
  ["how meaningful", "how_many"],
  ["how meaningless", "how_many"],
  ["how mellifluous", "how_many"],
  ["how lively", "how_many"],
  ["how marvelous", "how_many"],
  ["how lovely", "how_many"],
  ["how immoral", "how_many"],
  ["how logical", "how_many"],
  ["how lonely", "how_many"],
  ["how long", "how_many"],
  ["how long-lived", "how_many"],
  ["how loose", "how_many"],
  ["how lost", "how_many"],
  ["how loud", "how_many"],
  ["how magical", "how_many"],
  ["how lovable", "how_many"],
  ["how mad", "how_many"],
  ["how loving", "how_many"],
  ["how low", "how_many"],
  ["how loyal", "how_many"],
  ["how lucky", "how_many"],
  ["how luminous", "how_many"],
  ["how luscious", "how_many"],
  ["how lustful", "how_many"],
  ["how merciful", "how_many"],
  ["how loudly", "how_many"],
  ["how holy", "how_many"],
  ["how horrible", "how_many"],
  ["how repressed", "how_many"],
  ["how horrendous", "how_many"],
  ["how ready", "how_many"],
  ["how horny", "how_many"],
  ["how hopeless", "how_many"],
  ["how hopeful", "how_many"],
  ["how mellow", "how_many"],
  ["how homosexual", "how_many"],
  ["how humble", "how_many"],
  ["how hip", "how_many"],
  ["how hilarious", "how_many"],
  ["how high", "how_many"],
  ["how hideous", "how_many"],
  ["how hidden", "how_many"],
  ["how heterosexual", "how_many"],
  ["how hesitant", "how_many"],
  ["how mighty", "how_many"],
  ["how honest", "how_many"],
  ["how idiotic", "how_many"],
  ["how merciless", "how_many"],
  ["how messy", "how_many"],
  ["how meticulous", "how_many"],
  ["how immense", "how_many"],
  ["how immature", "how_many"],
  ["how imaginative", "how_many"],
  ["how illustrious", "how_many"],
  ["how illogical", "how_many"],
  ["how hot", "how_many"],
  ["how idle", "how_many"],
  ["how huge", "how_many"],
  ["how hysterical", "how_many"],
  ["how hypnotic", "how_many"],
  ["how hypersensitive", "how_many"],
  ["how hyperactive", "how_many"],
  ["how hungry", "how_many"],
  ["how humorous", "how_many"],
  ["how humid", "how_many"],
  ["how little", "how_many"],
  ["how ignorant", "how_many"],
  ["how inhibited", "how_many"],
  ["how indecent", "how_many"],
  ["how independent", "how_many"],
  ["how indifferent", "how_many"],
  ["how industrious", "how_many"],
  ["how inefficient", "how_many"],
  ["how inevitable", "how_many"],
  ["how infallible", "how_many"],
  ["how instinctive", "how_many"],
  ["how informed", "how_many"],
  ["how inconsiderate", "how_many"],
  ["how injurious", "how_many"],
  ["how innocent", "how_many"],
  ["how insane", "how_many"],
  ["how insatiable", "how_many"],
  ["how insignificant", "how_many"],
  ["how insistent", "how_many"],
  ["how inspirational", "how_many"],
  ["how loathsome", "how_many"],
  ["how infinite", "how_many"],
  ["how impotent", "how_many"],
  ["how immortal", "how_many"],
  ["how impatient", "how_many"],
  ["how imperceptible", "how_many"],
  ["how imperfect", "how_many"],
  ["how impermanent", "how_many"],
  ["how impersonal", "how_many"],
  ["how impervious", "how_many"],
  ["how impolite", "how_many"],
  ["how incredulous", "how_many"],
  ["how impossible", "how_many"],
  ["how incredible", "how_many"],
  ["how impressed", "how_many"],
  ["how impressive", "how_many"],
  ["how impulsive", "how_many"],
  ["how inauspicious", "how_many"],
  ["how incoherent", "how_many"],
  ["how incompetent", "how_many"],
  ["how incongruous", "how_many"],
  ["how instructive", "how_many"],
  ["how important", "how_many"],
  ["how large", "how_many"],
  ["how irresponsible", "how_many"],
  ["how irreverent", "how_many"],
  ["how irritated", "how_many"],
  ["how irritating", "how_many"],
  ["how jealous", "how_many"],
  ["how judgmental", "how_many"],
  ["how kind", "how_many"],
  ["how inspired", "how_many"],
  ["how lame", "how_many"],
  ["how invigorating", "how_many"],
  ["how laughable", "how_many"],
  ["how lazy", "how_many"],
  ["how lecherous", "how_many"],
  ["how legendary", "how_many"],
  ["how legitimate", "how_many"],
  ["how lenient", "how_many"],
  ["how liberal", "how_many"],
  ["how likely", "how_many"],
  ["how kindly", "how_many"],
  ["how intimidating", "how_many"],
  ["how insulted", "how_many"],
  ["how insulting", "how_many"],
  ["how intelligent", "how_many"],
  ["how intelligently", "how_many"],
  ["how intense", "how_many"],
  ["how intensive", "how_many"],
  ["how interested", "how_many"],
  ["how interesting", "how_many"],
  ["how involved", "how_many"],
  ["how intimidated", "how_many"],
  ["how inviting", "how_many"],
  ["how intoxicated", "how_many"],
  ["how intoxicating", "how_many"],
  ["how introspective", "how_many"],
  ["how intrusive", "how_many"],
  ["how invasive", "how_many"],
  ["how inventive", "how_many"],
  ["how invigorated", "how_many"],
  ["how mischievous", "how_many"],
  ["how intestinous", "how_many"],
  ["how possessive", "how_many"],
  ["how pleasing", "how_many"],
  ["how pleasurable", "how_many"],
  ["how poetic", "how_many"],
  ["how pointless", "how_many"],
  ["how poisonous", "how_many"],
  ["how polite", "how_many"],
  ["how polluted", "how_many"],
  ["how prejudice", "how_many"],
  ["how possessed", "how_many"],
  ["how playful", "how_many"],
  ["how possible", "how_many"],
  ["how potent", "how_many"],
  ["how powerful", "how_many"],
  ["how powerfully", "how_many"],
  ["how practical", "how_many"],
  ["how precious", "how_many"],
  ["how precise", "how_many"],
  ["how mindless", "how_many"],
  ["how positive", "how_many"],
  ["how persuasive", "how_many"],
  ["how peculiar", "how_many"],
  ["how penetrating", "how_many"],
  ["how perceptive", "how_many"],
  ["how perfect", "how_many"],
  ["how permanent", "how_many"],
  ["how pernicious", "how_many"],
  ["how perplexed", "how_many"],
  ["how perplexing", "how_many"],
  ["how pleased", "how_many"],
  ["how personal", "how_many"],
  ["how pleasant", "how_many"],
  ["how pervasive", "how_many"],
  ["how perverted", "how_many"],
  ["how pessimistic", "how_many"],
  ["how picky", "how_many"],
  ["how picturesque", "how_many"],
  ["how pious", "how_many"],
  ["how pitiful", "how_many"],
  ["how preoccupied", "how_many"],
  ["how persistent", "how_many"],
  ["how radiant", "how_many"],
  ["how psychic", "how_many"],
  ["how psychotic", "how_many"],
  ["how pure", "how_many"],
  ["how quarrelous", "how_many"],
  ["how queer", "how_many"],
  ["how quick", "how_many"],
  ["how quickly", "how_many"],
  ["how pregnant", "how_many"],
  ["how quietly", "how_many"],
  ["how protective", "how_many"],
  ["how radical", "how_many"],
  ["how rampant", "how_many"],
  ["how random", "how_many"],
  ["how rare", "how_many"],
  ["how rarely", "how_many"],
  ["how rational", "how_many"],
  ["how ravenous", "how_many"],
  ["how reactionary", "how_many"],
  ["how quiet", "how_many"],
  ["how professional", "how_many"],
  ["how prepared", "how_many"],
  ["how preposterous", "how_many"],
  ["how presumptuous", "how_many"],
  ["how pretentious", "how_many"],
  ["how pretty", "how_many"],
  ["how preventable", "how_many"],
  ["how primitive", "how_many"],
  ["how private", "how_many"],
  ["how provocative", "how_many"],
  ["how productive", "how_many"],
  ["how proud", "how_many"],
  ["how proficient", "how_many"],
  ["how profitable", "how_many"],
  ["how profound", "how_many"],
  ["how progressive", "how_many"],
  ["how promiscuous", "how_many"],
  ["how proper", "how_many"],
  ["how prosperous", "how_many"],
  ["how pathetic", "how_many"],
  ["how problematic", "how_many"],
  ["how negative", "how_many"],
  ["how narrow", "how_many"],
  ["how natural", "how_many"],
  ["how nauseated", "how_many"],
  ["how nauseating", "how_many"],
  ["how nauseous", "how_many"],
  ["how neat", "how_many"],
  ["how nebulous", "how_many"],
  ["how normal", "how_many"],
  ["how needy", "how_many"],
  ["how mythical", "how_many"],
  ["how nervous", "how_many"],
  ["how neurotic", "how_many"],
  ["how new", "how_many"],
  ["how nice", "how_many"],
  ["how nihilistic", "how_many"],
  ["how nimble", "how_many"],
  ["how noisy", "how_many"],
  ["how peaceful", "how_many"],
  ["how necessary", "how_many"],
  ["how moody", "how_many"],
  ["how miserable", "how_many"],
  ["how misinformed", "how_many"],
  ["how misleading", "how_many"],
  ["how mistaken", "how_many"],
  ["how misunderstood", "how_many"],
  ["how modern", "how_many"],
  ["how modest", "how_many"],
  ["how monotonous", "how_many"],
  ["how narcissistic", "how_many"],
  ["how monumental", "how_many"],
  ["how naked", "how_many"],
  ["how moral", "how_many"],
  ["how morbid", "how_many"],
  ["how moronic", "how_many"],
  ["how much", "how_many"],
  ["how mundane", "how_many"],
  ["how musical", "how_many"],
  ["how mysterious", "how_many"],
  ["how nosy", "how_many"],
  ["how monstrous", "how_many"],
  ["how outraged", "how_many"],
  ["how noncommunicative", "how_many"],
  ["how open", "how_many"],
  ["how openly", "how_many"],
  ["how open-minded", "how_many"],
  ["how optimistic", "how_many"],
  ["how organized", "how_many"],
  ["how original", "how_many"],
  ["how omniscient", "how_many"],
  ["how outgoing", "how_many"],
  ["how omnipotent", "how_many"],
  ["how outrageous", "how_many"],
  ["how overactive", "how_many"],
  ["how overwhelmed", "how_many"],
  ["how overwhelming", "how_many"],
  ["how overzealous", "how_many"],
  ["how painful", "how_many"],
  ["how parasitic", "how_many"],
  ["how miraculous", "how_many"],
  ["how otherworldly", "how_many"],
  ["how oblivious", "how_many"],
  ["how noteworthy", "how_many"],
  ["how noticeable", "how_many"],
  ["how nude", "how_many"],
  ["how numb", "how_many"],
  ["how numinous", "how_many"],
  ["how obdurate", "how_many"],
  ["how obedient", "how_many"],
  ["how on", "how_many"],
  ["how objective", "how_many"],
  ["how patient", "how_many"],
  ["how observant", "how_many"],
  ["how obsessed", "how_many"],
  ["how obsessive", "how_many"],
  ["how obstinate", "how_many"],
  ["how odd", "how_many"],
  ["how off", "how_many"],
  ["how offensive", "how_many"],
  ["how often", "how_many"],
  ["how old", "how_many"],
  ["how obese", "how_many"],
  ["isn't", "yes_no"],
  ["wasn't", "yes_no"],
  ["haven't", "yes_no"],
  ["is", "yes_no"],
  ["may", "yes_no"],
  ["must", "yes_no"],
  ["shall", "yes_no"],
  ["should", "yes_no"],
  ["hasn't", "yes_no"],
  ["was", "yes_no"],
  ["has", "yes_no"],
  ["were", "yes_no"],
  ["weren't", "yes_no"],
  ["will", "yes_no"],
  ["won't", "yes_no"],
  ["would", "yes_no"],
  ["wouldn't", "yes_no"],
  ["shouldn't", "yes_no"],
  ["didn't", "yes_no"],
  ["am", "yes_no"],
  ["are", "yes_no"],
  ["aren't", "yes_no"],
  ["can", "yes_no"],
  ["can't", "yes_no"],
  ["could", "yes_no"],
  ["have", "yes_no"],
  ["did", "yes_no"],
  ["aint", "yes_no"],
  ["do", "yes_no"],
  ["does", "yes_no"],
  ["doesn't", "yes_no"],
  ["don't", "yes_no"],
  ["had", "yes_no"],
  ["hadn't", "yes_no"],
  ["couldn't", "yes_no"],
  ["how do I look", "conversation"],
  ["how does she look", "conversation"],
  ["how does he look", "conversation"],
  ["how do we look", "conversation"],
  ["how excellent", "how_many"],
  ["how excited", "how_many"],
  ["don't worry", "how_many"],
  ["favorite book", "conversation"],
  ["favorite color", "conversation"],
  ["favorite movie", "conversation"],
  ["favorite song", "conversation"],
  ["how addicted", "how_many"],
  ["how addictive", "how_many"],
  ["how attached", "how_many"],
  ["how brown", "how_many"],
  ["how closed", "how_many"],
  ["how closely", "how_many"],
  ["how dare you", "conversation"],
  ["how dysfunctional", "how_many"],
  ["how empathetic", "how_many"],
  ["how extroverted", "how_many"],
  ["how gold", "how_many"],
  ["how green", "how_many"],
  ["how hardy", "how_many"],
  ["how ill", "how_many"],
  ["how illegal", "how_many"],
  ["how illicit", "how_many"],
  ["how illiterate", "how_many"],
  ["how intricate", "how_many"],
  ["how introverted", "how_many"],
  ["how invincible", "how_many"],
  ["how just", "how_many"],
  ["how liberated", "how_many"],
  ["how na�ve", "how_many"],
  ["how optimistic", "how_many"],
  ["how ordinary", "how_many"],
  ["how pink", "how_many"],
  ["how poor", "how_many"],
  ["how popular", "how_many"],
  ["how precocious", "how_many"],
  ["how predictable", "how_many"],
  ["how red", "how_many"],
  ["how resolute", "how_many"],
  ["how reticent", "how_many"],
  ["how sympathetic", "how_many"],
  ["how tangy", "how_many"],
  ["how temperamental", "how_many"],
  ["how transformative", "how_many"],
  ["how transforming", "how_many"],
  ["how unflappable", "how_many"],
  ["how unpopular", "how_many"],
  ["how unpredictable", "how_many"],
  ["how white", "how_many"],
  ["how yellow", "how_many"],
  ["to to to", "where"],
  ["when your", "conversation"],
  ["how detached", "how_many"],
  ["how clueless", "how_many"],
  ["how inspiring", "how_many"],
  ["how passionate", "how_many"],
  ["how opportunistic", "how_many"],
  ["how spoiled", "how_many"],
  ["how fearless", "how_many"],
  ["person who", "conversation"],
  ["people who", "conversation"],
  ["guy who", "conversation"],
  ["guys who", "conversation"],
  ["woman who", "conversation"],
  ["women who", "conversation"],
  ["man who", "conversation"],
  ["men who", "conversation"],
  ["boy who", "conversation"],
  ["boys who", "conversation"],
  ["girl who", "conversation"],
  ["girls who", "conversation"],
  ["when accepting", "conversation"],
  ["when asking", "conversation"],
  ["when borrowing", "conversation"],
  ["when breaking", "conversation"],
  ["when bringing", "conversation"],
  ["when buying", "conversation"],
  ["when being", "conversation"],
  ["when changing", "conversation"],
  ["when cleaning", "conversation"],
  ["when complaining", "conversation"],
  ["when dancing", "conversation"],
  ["when drinking", "conversation"],
  ["when driving", "conversation"],
  ["when eating", "conversation"],
  ["when explaining", "conversation"],
  ["when falling", "conversation"],
  ["when feeling", "conversation"],
  ["when finding", "conversation"],
  ["when fixing", "conversation"],
  ["when flying", "conversation"],
  ["when giving", "conversation"],
  ["when going", "conversation"],
  ["when having", "conversation"],
  ["when hearing", "conversation"],
  ["when learning", "conversation"],
  ["when leaving", "conversation"],
  ["when closing", "conversation"],
  ["when shutting", "conversation"],
  ["when paying", "conversation"],
  ["when playing", "conversation"],
  ["when putting", "conversation"],
  ["when running", "conversation"],
  ["when reading", "conversation"],
  ["when seeing", "conversation"],
  ["when sending", "conversation"],
  ["when sitting", "conversation"],
  ["when sleeping", "conversation"],
  ["when smoking", "conversation"],
  ["when speaking", "conversation"],
  ["when spending", "conversation"],
  ["when starting", "conversation"],
  ["when standing", "conversation"],
  ["when beginning", "conversation"],
  ["when taking", "conversation"],
  ["when talking", "conversation"],
  ["when teaching", "conversation"],
  ["when telling", "conversation"],
  ["when thinking", "conversation"],
  ["when traveling", "conversation"],
  ["when  trying", "conversation"],
  ["when turning", "conversation"],
  ["when using", "conversation"],
  ["when waiting", "conversation"],
  ["when waking", "conversation"],
  ["when wanting", "conversation"],
  ["when watching", "conversation"],
  ["when working", "conversation"],
  ["when worrying", "conversation"],
  ["when writing", "conversation"],
  ["when listening", "conversation"],
  ["when living", "conversation"],
  ["when looking", "conversation"],
  ["when losing", "conversation"],
  ["when making", "conversation"],
  ["when doing", "conversation"],
  ["when needing", "conversation"],
  ["when opening", "conversation"],
  ["whos", "who"],
  ["how intimate", "how_many"],
  ["how heavy", "how_many"],
  ["how light", "how_many"],
  ["how relaxed", "how_many"],
  ["how relaxing", "how_many"],
  ["how clearly", "how_many"],
  ["how delicate", "how_many"],
  ["how absent", "how_many"],
  ["how stiff", "how_many"],
  ["how suited", "how_many"],
  ["how timid", "how_many"],
  ["how false", "how_many"],
]);
