import React from "react";
import DeityBar from "./DeityBar";
import "./WelcomePage.css";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { AuthUserContext, withAuthentication } from "../Session";
import { Button } from "react-bootstrap";
import googleIcon from "../../assets/google_icon_mod.svg";
import fbIcon from "../../assets/facebook_icon.svg";
import MetaTags from "react-meta-tags";

const WelcomePage = (props) => {
  let goToLogin = () => {
    props.history.push("/signin");
  };
  let goToSignUp = () => {
    props.history.push("/signup");
  };
  let signInAnonymously = () => {
    props.firebase.signInAnonymously();
    props.history.push("/");
  };
  let signInWithGoogle = () => {
    props.firebase.signInWithGoogle();
  };
  let signInWithFacebook = () => {
    props.firebase.signInWithFacebook();
  };

  let goToChat = () => props.history.push("/chat");
  let authGroup = (
    <div className="navigationBtns">
      <Button id="signInBtn" onClick={goToLogin}>
        Sign In
      </Button>

      <Button id="signUpBtn" onClick={goToSignUp}>
        Sign Up
      </Button>
      <hr className="hr-text" data-content="Or Continue with" />
      <div className={"third-party-button-rack"}>
        <div
          className="signin-button signin-button-google"
          tabIndex={0}
          onClick={signInWithGoogle}
          alt="google sign in"
        >
          <img src={googleIcon} alt="google icon" />
        </div>
        <div
          className="signin-button signin-button-fb"
          tabIndex={0}
          onClick={signInWithFacebook}
          alt="facebook sign in"
        >
          <img src={fbIcon} alt="facebook icon" />
        </div>

        <Button id="anonymousBtn" onClick={signInAnonymously}>
          Anonymous
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <MetaTags>
        <title>The Divinator</title>
        <meta name="description" content="Welcome page for The Divinator" />
      </MetaTags>
      <h1>Welcome to the home of The Divinator</h1>
      <h2>Our stellar AI oracles await your questions</h2>
      <h3>A cosmic opportunity</h3>

      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <Button id="signed-in-btn" onClick={goToChat}>
              Proceed to chat
            </Button>
          ) : (
            authGroup
          )
        }
      </AuthUserContext.Consumer>
      <DeityBar />
    </div>
  );
};

export default withAuthentication(withFirebase(withRouter(WelcomePage)));
