let deityWelcomePage = {
  divinator: {
    title: "The world's greatest conversing deity",
    name: "The Divinator"
  },
  shakespeare: {
    title: "Tap the wisdom of The Bard",
    name: "Shakespeare Divinator"
  }
};

export default deityWelcomePage;
