import React from "react";
import { List, AutoSizer } from "react-virtualized";
let firstTime = true;
function ConversationPair(props) {
  const {
    conv,
    displayName,
    entityDisplayName,
    calculatedFontSize,
    themeName,
    style,
  } = props;

  return (
    <div
      style={{ ...style, fontSize: calculatedFontSize }}
      className={"whiteFont chat-pair"}
    >
      <ul className={`${themeName}-font-color`}>
        {displayName}: {conv.sent}
      </ul>
      <ul>
        {entityDisplayName}: {conv.response}
      </ul>
    </div>
  );
}

function ConversationContent(props) {
  function loadMoreRows() {
    props.loadMoreRows();
  }

  function _rowRenderer({ index, isScrolling, key, style }) {
    return (
      <ConversationPair
        style={style}
        themeName={props.themeName}
        calculatedFontSize={`${1}em`}
        entityDisplayName={props.entityDisplayName}
        displayName={props.displayName || "Anonymous"}
        conv={props.conversations[index]}
        key={key}
      />
    );
  }
  function _onScroll({ scrollTop }) {
    if (!firstTime) {
      if (scrollTop === 0) {
        loadMoreRows();
        firstTime = true;
      }
    } else {
      firstTime = false;
    }
  }
  let rowHeight = 150;
  return (
    <div style={{ height: "50vh" }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            className={"conversation-list"}
            height={height}
            overscanRowCount={10}
            rowCount={props.conversations.length}
            rowHeight={rowHeight}
            onScroll={_onScroll}
            scrollTop={props.scrollIndex * rowHeight}
            rowRenderer={_rowRenderer}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  );
}

export default ConversationContent;
