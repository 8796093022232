import { DANGER, OR, CONVERSATION } from "../../constants/intents";
import allIntents from "./allIntents";

function allPermutations(value) {
  const permutations = [];
  var sArray = value.split(" ");
  var n = sArray.length;
  for (var i = 0; i < n; i++) {
    for (var j = 0; j <= i; j++) {
      permutations.push(sArray.slice(j, n - i + j).join(" "));
    }
  }
  return permutations;
}
function getIntent(query) {
  const allPerms = allPermutations(query);
  const foundIntents = allPerms
    .map((p) => allIntents.get(p))
    .filter((x) => x !== undefined);
  return getMainIntent(foundIntents);
}

//If 'danger' is discovered always default to it, otherwise just take the first intent found
function getMainIntent(foundIntents) {
  if (foundIntents.length > 0) {
    return foundIntents.includes(DANGER) || foundIntents.includes(OR)
      ? CONVERSATION
      : foundIntents[0];
  } else return CONVERSATION;
}

export default getIntent;
