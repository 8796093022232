import React from "react";

import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../../components/Session";

const SignOutButton = props => {
  let signOut = () => {
    props.firebase.logOut();
    props.history.push("/");
  };
  let SignOutButton = (
    <Button variant="outline-danger" type="button" onClick={signOut}>
      Sign Out
    </Button>
  );
  return (
    <AuthUserContext.Consumer>
      {authUser => (authUser ? SignOutButton : null)}
    </AuthUserContext.Consumer>
  );
};

export default withRouter(withFirebase(SignOutButton));
